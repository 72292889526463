global.$ = global.jQuery = require('jquery');
require('jquery-waypoints');

function duelAffixTo() {
	return {
		restrict: 'A',
		controller: function() {
			this.locked = true;
			this.sizeOfLockedItem = 0;
		},
		link: function(scope, element, attrs, duelAffixToCtrl) {
			element.waypoint({
				offset: function() {
					var contextHeight = $.waypoints('viewportHeight');
					var elementHeight = $(this).outerHeight(true);
					//subtract off the size of the locked item so it doesn't jump until there's enough room to add the affixed item back
					return contextHeight - elementHeight - duelAffixToCtrl.sizeOfLockedItem;
				},
				handler: function(dir) {
					duelAffixToCtrl.locked = (dir !== 'down');
					if (scope.$root.$$phase !== '$apply' && scope.$root.$$phase !== '$digest')
						scope.$apply();
				}
			});

			scope.$watch(
				function() { return element.height(); },
				function(height) {
					var monitorTop = height + element.offset().top;
					var windowBottom = $.waypoints('viewportHeight');
					duelAffixToCtrl.locked = (monitorTop > windowBottom);
					$.waypoints('refresh');
				}
			);
		}
	};
}

function duelAffix() {
	return {
		restrict: 'A',
		require: '^duelAffixTo',
		link: function(scope, element, attrs, duelAffixToCtrl) {
			duelAffixToCtrl.sizeOfLockedItem = element.outerHeight(true);
			scope.$watch(
				function() { return duelAffixToCtrl.locked; },
				function(locked) {
					if (locked) {
						element.addClass('state-locked').removeClass('state-fluid');
						duelAffixToCtrl.sizeOfLockedItem = element.outerHeight(true);
					} else {
						element.addClass('state-fluid').removeClass('state-locked');
						duelAffixToCtrl.sizeOfLockedItem = 0;
					}
				}
			);
		}
	};
}

module.exports = {duelAffixTo: duelAffixTo, duelAffix: duelAffix};
