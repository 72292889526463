global.$ = global.jQuery = require('jquery');
global._ = require('underscore');
global.textile = require('textile-js');
require('jquery.easing');
require('bootstrap');
require('angular');
require('angular-bootstrap-npm');
require('ng-infinite-scroll');
require('angular-bindonce');
require('typeahead.js');
require('./legacy/ie8-string-trim');
require('./product-carousel');
require('angular-typeahead');
require('jquery-bbq-deparam');
require('angular-resource');
require('angular-cookies');
require('es5-shim');
require('ui-select');
var components = require('./directives/index');

angular.module('duel', [
    'ui.bootstrap',
    'infinite-scroll',
    'pasvaz.bindonce',
    'siyfion.sfTypeahead',
	'ui.select',
    components.name
]);