global.$ = global.jQuery = require('jquery');
require('modernizr');
require('jquery.columnizer');

function duelViewAll() {
	return {
		restrict: 'A',
		link: function($scope, $element, $attrs) {
            var element = $element;
			$element.click(function(event) {
				event.preventDefault();
				$element.parent().toggleClass("state-open");
				if (!Modernizr.csscolumns && !element.hasClass("columnized")) {
					$('.facet-content-full').columnize({ columns:3, buildOnce: true });
					element.addClass("columnized");
				}
			});
		}
	};
};

module.exports = duelViewAll;