/* Product Carousel
*  Use to create a paged carousel.
*  (C) 2013 Direct Supply
* */

window.dsProductCarousel = {
    setup: function(elem, containerSelector, holderSelector, itemSelector) {
        var containerSelector = containerSelector || ".carousel-holder";
        var holderSelector = holderSelector || ".carousel-items";
        var itemSelector = itemSelector || ".carousel-item";
        var elem = $(elem);

        // Navigation Click Events
        $(".carousel-control-next", elem).click( this.showNext );
        $(".carousel-control-prev", elem).click( this.showPrev );

        // Variables Setup
        var containerWidth = $(containerSelector, elem).width();
        $(elem).attr({
            "data-width": containerWidth,
            "data-pages": Math.ceil($(itemSelector, elem).length / 4),
            "data-current-page": 1,
            "data-container-sel": containerSelector,
            "data-holder-sel": holderSelector,
            "data-item-sel": itemSelector

        });

        // Set Holder Width
        var holderWidth = 0;
        $(".product-listing-holder-row", elem).each( function(i, d) {
            holderWidth += $(d).outerWidth(true);
        });
        $(holderSelector, elem).width(holderWidth);

        //Set Current Page
        $(containerSelector, elem).scrollLeft(0);

        // Set Paging
        $(".carousel-data-page-count", elem).text(Math.ceil($(itemSelector, elem).length / 4));
        $(".carousel-data-current-page", elem).text(1);

    },
    showNext: function() {
        var parent = $(this).parents(".product-carousel"),
            currentPage = parseInt($(parent).attr("data-current-page"));
        dsProductCarousel.movePanel(currentPage + 1, parent);
    },
    showPrev: function() {
        var parent = $(this).parents(".product-carousel"),
            currentPage = parseInt($(parent).attr("data-current-page"));
        dsProductCarousel.movePanel(currentPage - 1, parent);
    },
    movePanel: function(page, parent) {
        var totalPages = $(parent).attr("data-pages"),
            currentPage = parseInt($(parent).attr("data-current-page")),
            carouselWidth = $(parent).attr("data-width"),
            containerSelector = $(parent).attr("data-container-sel"),
            gotoPage = page;
        if(page > currentPage && page > totalPages) {
            gotoPage = 1;
        }
        if(page < currentPage && page < 1) {
            gotoPage = totalPages
        }
        $(parent).attr("data-current-page", gotoPage);
        $(".carousel-data-current-page", parent).text(gotoPage);
        $(containerSelector, parent).animate({scrollLeft: carouselWidth * (gotoPage - 1)}, 1000, "easeInOutQuint");
    }
};